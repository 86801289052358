import type { AviaryIntentions } from "aviary-tokens";

import type { ButtonProps } from "@aviary/components/Button/Button";
import { Button } from "@aviary/components/Button/Button";

interface IconButtonProps extends ButtonProps {
  "aria-label": string;
  intention?: Extract<
    AviaryIntentions,
    "system" | "textSystem" | "danger" | "textDanger" | "lightFilled"
  >;
}

const IconButton = ({ children, ...rest }: IconButtonProps) => {
  return (
    <Button isIcon {...rest}>
      {children}
    </Button>
  );
};

export type { IconButtonProps };
export { IconButton };
