import { getApolloContext } from "@apollo/client";
import { captureMessage, captureException } from "@sentry/react";
import { useContext, useEffect, useMemo } from "react";

import { hashCode } from "@helpers/hashCode";
import { useExperimentVar } from "@shared/context/experiment";
import { getClient } from "@shared/data/client/getClient";
import { useExperimentCreateMutation } from "@shared/data/mutations";
import { isProduction, isTest } from "@shared/utils";
import { addExperimentToSentryContext } from "@shared/utils/sentryContextExperiments/sentryContextExperiments";

import { useSharedGlobalConfig } from "../useSharedGlobalConfig";

import { useUnauthExperimentStarterMutation } from "./UnauthExperimentStarter.mutation";

let cache = {};

/**
 * @deprecated for use in specs only!
 */
// eslint-disable-next-line @typescript-eslint/naming-convention
const unstable_resetCache = () => {
  cache = {};
};

// This fallbackApolloClient exists in the case where useExperiment is used in a SPA without an ApolloClientProvider
// In this case, no experiment data will be logged, but it also will not throw errors breaking the app for the user
// This is mainly to address issues in the admin application
const fallbackApolloClient = getClient();

const shouldCall = (experiment: string, group: string, subjectId: string) => {
  return !cache[experiment]?.[group]?.[subjectId];
};

const called = (experiment: string, group: string, subjectId: string) => {
  if (!subjectId) return;
  if (!cache[experiment]) cache[experiment] = {};
  if (!cache[experiment][group]) cache[experiment][group] = {};
  if (!cache[experiment][group][subjectId]) cache[experiment][group][subjectId] = {};
  cache[experiment][group][subjectId] = true;
};

const logOnce = (
  logFn: (groupName: string) => void,
  experiment: string,
  owner: string,
  group: string,
  subjectId: string
) => {
  if (shouldCall(experiment, group, subjectId)) {
    logFn(group);
    called(experiment, group, subjectId);
  }
};

/**
 * What is this?
 *
 * useExperiment is a tool which returns one of a random set of group names you pass in
 * based on the subject you specify.
 *
 * If you are certain your experiment should only be run from an authenticated or unauthenticated context,
 * you can use the useAuthedExperiment or useUnauthedExperiment hook respectively to avoid any potential sentry warnings.
 * If you'd like to log an experiment in both authenticated and unauthenticated context,
 * feel free to use this hook with both the authedExperiment and unauthedExperiment props set to true.
 * returned values will be evenly distributed across the subject,
 * meaning you can expect an even 50/50 split if passing in 2 groups,
 * 33/33/33 split for 3 groups etc.
 *
 * Note: The returned value will *always* be the same for a specific authenticated subject, even across devices.
 * If running an unauthed experiment the value will always remain the same as far as they are on the same device,
 * due to how we capture anonymous mixpanel events.
 *
 * Note on using for development or staging: You can test this with ExperimentTester. Simply press cmd+e to open the menu.
 */

interface ExperimentOptions<T extends string> {
  /**
   * Alternative to userId, the value you wish to split the experiment on
   *
   * @default null
   */
  subjectId?: string;
  /**
   * Name of the experiment.
   */
  experiment: string;
  /**
   * Who's in charge of running the experiment? (Your name or team name)
   */
  owner?: string;
  /**
   * Names of your experiment groups. The useExperiment hook will return a random one of these values
   */
  groups: readonly T[];
  /**
   * Which group to specify always when in an e2e environment (static value).
   * This is useful when building flows and not having to worry about every case for agnostic flows.
   */
  e2eGroup?: T;
  /**
   * Specify whether to log based on any secondary conditions
   * This is useful when you have additional conditionals for add a subject to the experiment.
   */
  shouldLog?: boolean;
  /**
   * Specify whether this experiment should be run from an authenticated context.
   * If this hook is called with this prop set to true while being in an unauthenticated context,
   * a sentry warning will be raised. To silence, switch to the one of the variants of this hook mentioned above.
   * @default true
   */
  authedExperiment?: boolean;
  /**
   * Specify whether this experiment should be run from an unauthenticated context
   * e.g: login, signup and open catalog
   * If this hook is called with this prop set to true while being in an authenticated context,
   * a sentry warning will be raised. To silence, switch to the one of the variants of this hook mentioned above.
   * @default false
   */
  unauthedExperiment?: boolean;
}

type UseExperimentProps<T extends string> = ExperimentOptions<T> &
  ({ authedExperiment?: true; owner: string } | { authedExperiment: false; owner?: string }) &
  (
    | { authedExperiment?: true; unauthedExperiment?: false; shouldLog?: boolean }
    | { unauthedExperiment: true; authedExperiment: false; shouldLog?: boolean }
    | { shouldLog: false }
  );

const useExperiment = <T extends string>({
  subjectId,
  experiment,
  owner,
  groups,
  e2eGroup,
  shouldLog = true,
  authedExperiment = true,
  unauthedExperiment = false,
}: UseExperimentProps<T>): T => {
  // Using this instead of useApolloClient to sircumvent thrown errors
  // This is needed to support experiments in the admin application
  // Something that the Phoenix team needs for aviary components
  const apolloContext = useContext(getApolloContext());

  const { e2e, isUnauthenticated, isAdminImpersonating } = useSharedGlobalConfig();
  const { userId, analyticsAnonymousId, overrideValues, setOverride, setCurrentExperiments } =
    useExperimentVar();

  const handleError = error => {
    if (isUnauthenticated) {
      captureException(error, {
        extra: {
          experiment,
          groups,
          analyticsAnonymousId,
        },
      });
    } else {
      captureException(error, {
        extra: {
          experiment,
          groups,
          owner,
          subjectId,
        },
      });
    }
  };

  // ANY mutation used in experiments must pass the client directly
  // This is so that useExperiment can "silently" work outside of a SPA, for example admin
  const [logAuthedMutation] = useExperimentCreateMutation({
    onError: handleError,
    client: apolloContext?.client || fallbackApolloClient,
  });

  // ANY mutation used in experiments must pass the client directly
  // This is so that useExperiment can "silently" work outside of a SPA, for example admin
  const [logUnauthedMutation] = useUnauthExperimentStarterMutation({
    onError: handleError,
    client: apolloContext?.client || fallbackApolloClient,
  });

  const getSubjectId = () => (isUnauthenticated ? analyticsAnonymousId : getAuthedSubjectId());

  const getAuthedSubjectId = () => {
    if (subjectId) return subjectId;

    return userId;
  };

  const logAuthedExperiment = (group: T) => {
    if (!authedExperiment) return;
    const authedSubjectId = getAuthedSubjectId();
    if (authedSubjectId && authedExperiment) {
      logAuthedMutation({
        variables: {
          input: {
            experiment,
            owner,
            group,
            subjectId: authedSubjectId,
          },
        },
      });
    } else {
      captureMessage(
        `${experiment} is being called in an unauthenticated context, there is no subjectId`,
        "warning"
      );
    }
  };

  const logUnauthedExperiment = (group: T) => {
    if (!unauthedExperiment) return;
    if (analyticsAnonymousId) {
      logUnauthedMutation({
        variables: { input: { experiment, experimentVariant: group } },
      });
    } else {
      captureMessage(`${experiment} is being called without an analyticsAnonymousId`, "warning");
    }
  };

  const mod = (value: number, by: number) => ((value % by) + by) % by;
  const selectGroup = (): T => {
    if (e2e) return e2eGroup ?? groups[0];

    const count = groups.length;
    const value = hashCode(`${experiment}${getSubjectId()}`);
    const index = mod(value, count);

    return [...groups].sort()[index];
  };

  const log = (groupName: T) => {
    if (!shouldLog) return;

    // Don't log if there's no Provider giving access to the apollo client
    // This happens on the admin side, graphiql etc
    if (!apolloContext.client) return;

    // Only log if the user isn't being impersonated
    if (isProduction() && isAdminImpersonating) return;

    isUnauthenticated ? logUnauthedExperiment(groupName) : logAuthedExperiment(groupName);
  };

  useEffect(() => {
    if (shouldLog && !isTest()) {
      logOnce(log, experiment, owner, selectGroup(), getSubjectId());
    }
  }, [shouldLog]);

  useEffect(() => {
    if (isTest()) return;
    setCurrentExperiments({ experimentName: experiment, groups, isAdminImpersonating });
    if (!overrideValues[experiment]) {
      setOverride({ experimentName: experiment, group: selectGroup(), isAdminImpersonating });
    }
  }, [overrideValues]);

  // useMemo is required here because it will run as the first render is occurring
  // If we run this only after the first render, it won't register any experiments being used during that render
  useMemo(() => {
    addExperimentToSentryContext({ experimentName: experiment, group: selectGroup() });
  }, [addExperimentToSentryContext, experiment, selectGroup]);

  // allow admins that are impersonating users in prod to specify overrides
  if ((isAdminImpersonating || !isProduction()) && overrideValues[experiment]) {
    return overrideValues[experiment] as T;
  }

  if (!getSubjectId() || isTest()) return groups[0];

  return selectGroup();
};

export { useExperiment, unstable_resetCache, type UseExperimentProps };
