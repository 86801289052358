import type { SerializedStyles, Theme } from "@emotion/react";
import { useTheme } from "@emotion/react";
import type { HTMLProps, ReactNode } from "react";

import { Skeleton } from "../Skeleton";

import * as styles from "./Typography.styles";

export type ParagraphType = "body" | "footnote" | "caption" | "subcaption";
export type HeaderType = "h1" | "h2" | "h3" | "h4" | "h5";
export type TypographyType = HeaderType | ParagraphType;

interface TypographyProps extends HTMLProps<HTMLElement> {
  /**
   * Determines the heading size
   *
   * @default body
   */
  type?: TypographyType;
  /**
   * Option for overriding size of heading tags
   * Only applies when using type h1, h2, h3, h4
   */
  sizeOverride?: TypographyType;
  /**
   * Option to indent the text
   *
   * @default false
   */
  isIndented?: boolean;
  /**
   * An option to utilize the secondary weight for that typography type
   *
   * @default false
   */
  isSecondaryWeight?: boolean;
  /**
   * Option to set the block to extend 100% width
   *
   * @default false
   */
  isFullWidth?: boolean;
  /**
   * Option to remove bottom margins from element
   *
   * @default false
   */
  isMarginless?: boolean;
  /**
   * Option to have lighter font color
   *
   * @default false
   */
  isSubdued?: boolean;
  /**
   * Displays the corresponding Skeleton
   *
   * @default false
   */
  isSkeleton?: boolean;
  /**
   * Typography content, basically text
   *
   * @default undefined
   */
  children?: ReactNode;
  /**
   * Allows you to pass custom styles, use sparingly!
   */
  customStyles?:
    | SerializedStyles
    | (SerializedStyles | ((theme: Theme) => SerializedStyles))[]
    | ((theme: Theme) => SerializedStyles);
  /**
   * Used to add unique identifiers to be used by End-to-end testing
   */
  dataE2e?: string;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/design-tokens/Typography
 */
const Typography = ({
  type = "body",
  sizeOverride,
  isIndented,
  isSecondaryWeight = false,
  isFullWidth,
  isMarginless,
  isSubdued = false,
  isSkeleton,
  children,
  customStyles,
  ref: unusedRef,
  dataE2e,
  ...rest
}: TypographyProps) => {
  const theme = useTheme();

  const titleStyleBuilder = () => {
    return [
      styles.titleBase,
      styles.titleSizes[type](isSecondaryWeight, theme),
      isSubdued && styles.isSubdued,
      isIndented && styles.indented,
      isFullWidth && styles.fullwidth,
      !isMarginless && styles.margin,
      sizeOverride && styles.sizeOverride[sizeOverride](isSecondaryWeight, theme),
      customStyles && customStyles,
    ];
  };

  const paragraphStyleBuilder = () => {
    return [
      styles.paragraphBase,
      styles.paragraphSizes[type](isSecondaryWeight, theme),
      isSubdued && styles.isSubdued,
      isFullWidth && styles.fullwidth,
      !isMarginless && styles.margin,
      sizeOverride && styles.sizeOverride[sizeOverride](isSecondaryWeight, theme),
      customStyles && customStyles,
    ];
  };

  const renderType = () => {
    switch (type) {
      case "h1":
        return (
          <h1 css={titleStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </h1>
        );
      case "h2":
        return (
          <h2 css={titleStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </h2>
        );
      case "h3":
        return (
          <h3 css={titleStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </h3>
        );
      case "h4":
        return (
          <h4 css={titleStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </h4>
        );
      case "h5":
        return (
          <h5 css={titleStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </h5>
        );
      default:
        return (
          <p css={paragraphStyleBuilder()} data-e2e={dataE2e} {...rest}>
            {children}
          </p>
        );
    }
  };

  const getSize = () => {
    if (sizeOverride) {
      return sizeOverride;
    }

    return type ? type : "body";
  };

  const getElement = () => {
    if (isSkeleton) {
      return <Skeleton type="typography" typographySize={getSize()} isFullWidth={isFullWidth} />;
    }

    return renderType();
  };

  return getElement();
};

export { Typography, type TypographyProps };
