import { useContext } from "react";

import { ServerContext } from "@shared/context";

const useServer = () => {
  const context = useContext(ServerContext);
  const ssr = typeof window === "undefined";

  return { ...context, ssr };
};

export { useServer };
