import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const UNAUTH_EXPERIMENT_STARTER_MUTATION = gql`
  mutation ExperimentStarter_Shared_Mutation($input: AnalyticsStartExperimentInput!) {
    analyticsStartExperiment(input: $input) {
      clientMutationId
    }
  }
`;

interface UnauthExperimentStarterData {
  analyticsStartExperiment: {
    clientMutationId: string;
  };
}

interface Variables {
  input: {
    experiment: string;
    experimentVariant: string;
  };
}

const useUnauthExperimentStarterMutation = (
  options?: MutationHookOptions<UnauthExperimentStarterData, Variables>
) =>
  useMutation<UnauthExperimentStarterData, Variables>(UNAUTH_EXPERIMENT_STARTER_MUTATION, options);

export type { UnauthExperimentStarterData };
export { useUnauthExperimentStarterMutation, UNAUTH_EXPERIMENT_STARTER_MUTATION };
