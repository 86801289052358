import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

const EXPERIMENT_CREATE_MUTATION = gql`
  mutation ExperimentCreate_Shared_Mutation($input: ExperimentCreateInput!) {
    experimentCreate(input: $input) {
      clientMutationId
    }
  }
`;

interface ExperimentCreateData {
  experimentMutation: {
    clientMutationId?: string;
  };
}

interface Variables {
  input: {
    experiment: string;
    owner: string;
    group: string;
    subjectId: string;
  };
}

const useExperimentCreateMutation = (
  options?: MutationHookOptions<ExperimentCreateData, Variables>
) => useMutation<ExperimentCreateData, Variables>(EXPERIMENT_CREATE_MUTATION, options);

export type { ExperimentCreateData };
export { useExperimentCreateMutation, EXPERIMENT_CREATE_MUTATION };
